
    
<template>
  <div class="dataset-page">
    <!-- banner -->
    <div class="dataset-page_banner">
      <!-- title num -->
      <div class="line"></div>
      <div class="timeline-list container">
        <div
          class="timeline-item"
          v-for="(item, index) in timelineList"
          :key="index"
        >
          <div class="item-date">{{ item.date }}</div>
          <img
            class="item-img"
            src="@/assets/images/leaderboard/time_icon.png"
            alt=""
          />
          <div class="item-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="dataset-page_type container">
      <!-- 排行榜维度板块 -->
      <div class="type_content">
        <el-tabs
          tab-position="left"
          class="left-tab"
          v-model="modelTabsActvieName"
          @tab-click="handleDimClick"
        >
          <el-tab-pane
            v-for="leftItem in dimensionList"
            :key="leftItem.ChineseName"
            :label="leftItem.ChineseName"
            :name="leftItem.ChineseName"
          >
            <div class="content-box">
              <div class="header" :class="[leaderBoardPath]">
                <div class="title">{{ leaderBoardName }}</div>
                <div class="option d-flex">
                  <!-- 日期选择 -->
                  <!-- <el-select v-model="dateValue" size="mini" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> -->

                  <!-- 中英文切换 -->
                  <!-- <el-radio-group
                    v-model="switchRadio"
                    text-color="#000"
                    fill="#FFF"
                    size="mini"
                    style="margin-left: 32px"
                    @input="changeSwitchRadio"
                  >
                    <el-radio-button label="总榜"></el-radio-button>
                    <el-radio-button label="中文"></el-radio-button>
                    <el-radio-button label="英文"></el-radio-button>
                  </el-radio-group> -->
                </div>
              </div>

              <!-- 榜单列表 -->
              <div class="table-box">
                <el-table
                  :data="tableData"
                  style="width: 100%; overflow: auto"
                  class="table-list"
                  row-key="id"
                >
                  <el-table-column type="index" :index="indexMethod">
                  </el-table-column>
                  <el-table-column
                    v-for="(item, i) in boardList"
                    :key="i"
                    :prop="item.name"
                    :label="item.label"
                    :sortable="true"
                  >
                  </el-table-column>
                </el-table>

                <!-- 金字塔 -->
                <!-- <div class="board-box" v-if="!leaderBoardPath">
                  <img
                    class="board-img"
                    src="@/assets/images/leaderboard/board.png"
                    alt=""
                  />

                  <div class="level">
                    <div
                      class="item"
                      v-for="item in levelList"
                      :key="item.level"
                    >
                      <div class="left">{{ item.level }}</div>
                      <div class="right">
                        <div
                          class="name"
                          v-for="(item1, index) in item.data"
                          :key="index"
                        >
                          {{ item1 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DimensionList from "@/assets/data/dimension/dimension.json";
import LeaderBoardList from "@/assets/data/leaderboard/leaderboard.json";

export default {
  data() {
    return {
      leaderBoardList: LeaderBoardList.common,
      // 表单数据
      tableData: [],
      // 表单列对象
      boardList: [
        { name: "model", label: "模型名" },
        { name: "company", label: "公司名" },
        { name: "open_source", label: "开源/闭源" },
        { name: "score", label: "分数" },
      ],
      options: [
        {
          value: "1",
          label: "2024年7月榜单",
        },
        {
          value: "2",
          label: "2024年6月榜单",
        },
        {
          value: "3",
          label: "2024年5月榜单",
        },
        {
          value: "4",
          label: "2024年4月榜单",
        },
        {
          value: "5",
          label: "2024年3月榜单",
        },
      ],
      dateValue: "1",
      switchRadio: "总榜",
      levelList: [
        {
          level: "A",
          data: [],
        },
        {
          level: "B",
          data: [],
        },
        {
          level: "C",
          data: [],
        },
        {
          level: "D",
          data: [],
        },
      ],
      dimensionList: DimensionList.common, //维度列表

      modelTabsActvieName: "",
      timelineList: [
        {
          date: "2024-08-28",
          desc: "84个通用维度数据集整理完毕",
        },
        {
          date: "2024-09-03",
          desc: "待测评主流大模型收集完毕",
        },
        {
          date: "2024-09-06",
          desc: "新增金融领域数据集",
        },
        {
          date: "2024-09-14",
          desc: "新增医疗领域数据集",
        },
        {
          date: "2024-09-20",
          desc: "通用维度数据集更新",
        },
        {
          date: "2024-10-22",
          desc: "10月通用维度大模型测评榜单更新",
        },
        {
          date: "2024-10-29",
          desc: "10月医疗领域大模型测评榜单更新 ",
        },
        {
          date: "2024-10-31",
          desc: "10月金融领域大模型测评榜单更新",
        },
      ],

      rankList: [
        {
          text: "中医排行榜(CTCMB)",
          path: "CTCMB",
        },
        {
          text: "医疗排行榜（Med）",
          path: "med",
        },
        {
          text: "机器人排行榜（Robot）",
          time: "2024-09-25",
          path: "robot",
        },
        {
          text: "金融排行榜（Finance）",
          time: "2024-09-25",
          path: "finance",
        },
        {
          text: "硬件排行榜（Hardware）",
          path: "hardware",
        },
        {
          text: "代码排行榜（Code）",
          path: "code",
        },
      ],
      leaderBoardName: "", //排行榜模块名称
      leaderBoardPath: "",

      dimensionIndex: 0, //维度下标

      currentSocreLists: [], //当前维度下的socreLists
    };
  },
  watch: {
    // 监听整个 $route 对象
    $route: function (to, from) {
      // to 和 from 分别代表新旧路由
      console.log("路由变化了 leaderBoard", to, from);

      // 赋值 leaderBoardName  leaderBoardPath逻辑
      // const name = localStorage.getItem("leaderboardName");
      // if (name) {
      //   this.leaderBoardName = name;
      // }

      // this.leaderBoardPath = localStorage.getItem("leaderboardPath");
    },
  },
  created() {
    console.log("this.$route", this.$route);
    // const path = this.$route.params.path;

    // 手动输入LeaderBoard 模块逻辑监听 leaderBoard/code
    // if (path) {
    //   const target = this.rankList.find((item) => item.path == path);
    //   localStorage.setItem("leaderboardPath", target.path);
    //   localStorage.setItem("leaderboardName", target.text);
    // } else {
    //   localStorage.removeItem("leaderboardPath");
    //   localStorage.setItem("leaderboardName", "大语言模型榜单");
    // }

    this.getTableData();
  },
  mounted() {
    // console.log("33333333", localStorage.getItem("leaderboardName"));
    // const name = localStorage.getItem("leaderboardName");
    // if (name) {
    //   this.leaderBoardName = name;
    // }
    // this.leaderBoardPath = localStorage.getItem("leaderboardPath");
  },
  methods: {
    // lang 切换
    changeSwitchRadio(lang) {
      console.log("lang", lang);
      this.getTableData();
    },

    // 维度切换
    handleDimClick(tab) {
      const index = tab.index;
      this.dimensionIndex = index;
      this.getTableData();
    },
    // 获取tableData方法
    getTableData() {
      const name = this.dimensionList[this.dimensionIndex].ChineseName;
      this.leaderBoardName = name;
      this.modelTabsActvieName = name
      console.log('NAME',this.leaderBoardName)
      console.log('this.leaderBoardList',this.leaderBoardList)
      
      // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      const target = this.leaderBoardList.find(
        (item) => item.dimension === name
      )
      const socreList = target.score_lists;
      
      this.currentSocreLists = socreList;
      // 筛选当前 lang 总榜/中文/英文
      this.tableData = socreList.find(
        (item) => item.lang === this.switchRadio
      ).list;

      // // 找出对应等级
      // this.levelList[0].data = this.processLevelData(this.tableData, "A", 2);
      // this.levelList[1].data = this.processLevelData(this.tableData, "B", 2);
      // this.levelList[2].data = this.processLevelData(this.tableData, "C", 2);
      // this.levelList[3].data = this.processLevelData(this.tableData, "D", 2);
    },
    // 定义一个辅助函数来处理过滤、排序和截取操作
    processLevelData(tableData, level, limit) {
      return tableData
        .filter((item) => item.level === level)
        .sort((a, b) => a.rank - b.rank)
        .slice(0, limit)
        .map((item) => item.model);
    },

    indexMethod(index) {
      return index + 1;
    },
    jumpDetail(item) {
      console.log("item.", item);
      this.$router.push({
        path: "/model-detail",
        query: {
          name: item.name,
        },
      });
    },
  },
};
</script>
  <style lang="scss" scoped >
::v-deep .el-tabs__item.is-disabled {
  font-weight: 400;
  font-size: 14px;
  color: #4e5969;
}

::v-deep .el-tabs--left .el-tabs__header.is-left {
  // width: 400px;
}


.dataset-page {
  .dataset-page_banner {
    background: url("~@/assets/images/leaderboard/header_bg.png");
    height: 326px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: relative;
    .line {
      height: 2px;
      width: 100%;
      background-color: #94bfff;
      position: absolute;
      left: 0;
      top: 176px;
    }
    .timeline-list {
      width: 100%;
      padding-top: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      .timeline-item {
        width: 150px;
        height: 160px;
      }
      .timeline-item:not(:last-child) {
        margin-right: 40px;
      }
      .item-date {
        font-weight: bold;
        font-size: 16px;
        color: #1d2129;
        margin-bottom: 9px;
      }
      .item-img {
        width: 24px;
        height: 80px;
        margin-bottom: 9px;
      }
      .item-desc {
        font-weight: 400;
        font-size: 14px;
        color: #86909c;
      }
    }
  }

  .dataset-page_type {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 24px;

    .type_content {
      .content-box {
        .header {
          border-radius: 4px;
          background: #0e42d2;
          border: 1px solid #e5e6eb;
          text-align: left;
          padding: 19px 16px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #fff;
          }
          .option {
            ::v-deep .el-radio-button--mini .el-radio-button__inner {
              color: #ffffff !important;
              background-color: #0e42d2 !important;
              border-color: #fff !important;
              box-shadow: -1px 0 0 0 #fff !important;
            }

            ::v-deep
              .el-radio-button__orig-radio:checked
              + .el-radio-button__inner {
              color: #000 !important;
              background-color: #fff !important;
              border-color: #fff !important;
              box-shadow: -1px 0 0 0 #fff !important;
            }
          }
        }
        .code,
        .robot,
        .finance,
        .med,
        .hardware {
          height: 106px;
          background-size: cover;
          background-position: center;
          background-color: #fff;
        }
        .code {
          background: url("~@/assets/images/leaderboard/title_code.png");
        }
        .robot {
          background: url("~@/assets/images/leaderboard/title_robot.png");
        }
        .finance {
          background: url("~@/assets/images/leaderboard/title_finance.png");
        }
        .med {
          background: url("~@/assets/images/leaderboard/title_med.png");
        }

        .hardware {
          background: url("~@/assets/images/leaderboard/title_hardware.png");
        }

        .table-box {
          display: flex;
          .board-box {
            width: 574px;
            display: flex;

            .level {
              display: flex;
              flex-direction: column;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 82px;
              width: 310.5px;
              margin-bottom: 24px;
              box-shadow: 3px 3px 0 rgba(201, 240, 255, 0.45);
              .left {
                font-weight: 500;
                font-size: 56px;
                color: #bedaff;
                line-height: 64px;
              }
              .right {
                text-align: right;
                font-weight: 400;
                font-size: 12px;
                color: #1d2129;
                padding-right: 20px;
              }
            }
          }
          .board-img {
            width: 240px;
            height: 456px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
</style>